h2 {
    display: block;
    left: 0px;
    font-size: min(2vmax, 24px);
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: lighter;
    text-align: center;
}


h3 {
    font-size: min(1.5vmax, 30px);
}

h4 {
    font-size: min(1.5vmax, 30px);
    font-weight: lighter;
}

h3, h4 {
    width: 50%;
    height: 60px;
    margin: 0;
    padding: 0;
    display: inline;
}

item {
    display: inline-block;
    width: 150px;
}
