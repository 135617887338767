.header {
    text-align: center;
    padding: 20px;
}

h1 {
    display: block;
    font-size: min(4vmax, 48px);
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

h2 {
    display: block;
    font-size: min(2vmax, 24px);
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: lighter;
}

h3 {
    font-size: min(2vmax, 36px);
    text-align: center;
}

.nav ul {
    width: 100%;
    list-style: none;
    margin: 8px;
    padding: 0;
    white-space: nowrap;
}

.nav li {
    display: inline-block;
    padding: 0 min(1.75vmax, 20px);
    font-size: min(1.75vmax, 18px);
    font-weight: bolder;
}

body {
    background: #f4f2e9;
    margin: 10px;
    text-align: center;
    font-family: "Lucida Console", "Courier New", monospace;
    font-size: min(1.25vmax, 16px);
    /* -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale; */
}

.layoutManager {
    display: inline-block;
    width: min(90%, 850px);
    text-align: left;
}

.imgContent {
    margin-block-start: 0.8em;
    position: relative;
    right: 0;
    display: float;
    float: right;
    width: 45%;
    padding: min(2%, 10px);
    vertical-align: middle;
}

.imgGallery {
    margin-block-start: 0.8em;
    position: relative;
    width: 60%;
    padding: min(2%, 10px);
    vertical-align: middle;
}

.homeInfo {
    text-align: left;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
                 monospace;
}

hr {
    width: min(90%, 850px);
}

footer {
    position: fixed;
    bottom:0;
    width:100%;
    margin-block-start: 1em;
    margin-block-end: 1em;
}
